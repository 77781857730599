import React, { useEffect, useLayoutEffect, useState } from 'react';
import LoginProvider from '../components/Login/context/LoginProvider';
import RainbowLaneStyled from '../components/Login/styles/RainbowLaneStyled';
import LoginStyled, {
  LogoContainerStyled,
  LoaderContainerStyled,
} from '../components/Login/styles/LoginStyled';
import {
  LogoSVG
} from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/miscellaneous'
import { LoaderBlock } from '@womcorp/wom-miwom-ui-kit/dist/components/Loader';
import { RedirectionPageType } from '../components/Login/@types';
import { FlexLayout } from '@womcorp/wom-miwom-ui-kit/dist/components/Layout';
import {
  isBrowser,
  navigate,
  localTokenHandler,
  channelRedirect,
} from '../common/Utils';
import { refreshToken } from '../services/OTPServices';

const Login: RedirectionPageType = ({ pageContext: { channel , initialUniverse }}) => {
  const [token, ] = useState(localTokenHandler.get());
  const channelLogin = (() => {
    switch (channel) {
      case 'bam':
      case 'womtv':
      case 'womfibra':
        return channel;
      default:
        return 'movil'
    }
  })();

  useLayoutEffect(() => {
    if (isBrowser()) {
      const [url, tkn] = window.location.href.split('#');
      window.localStorage.setItem('url_redirect', url.split('?path=')[1] || '');
      if (!token && !tkn) {
        navigate(`/login/${channelLogin}`);
      } else if (tkn) {
        localTokenHandler.set(tkn);
        window.location.replace(url);
      }
    }
  }, []);

  useEffect(() => {
    if (isBrowser() && token) {
      refreshToken(token)
        .then(() => {
          channelRedirect({ channel });
        })
        .catch(() => {
          navigate(`/login/${channel}`);
        })
    }
  }, []);

  return (
    <LoginProvider initialUniverse={initialUniverse}>
      <RainbowLaneStyled />
      <LoginStyled>
        <LogoContainerStyled>
          <LogoSVG />
        </LogoContainerStyled>
        <FlexLayout padding={'32px 0 0 0'}>
          <LoaderContainerStyled>
            <LoaderBlock />
          </LoaderContainerStyled>
        </FlexLayout>
      </LoginStyled>
    </LoginProvider>
  );
}

export default Login;
